(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

var _header = require("./modules/header");
var _formValidation = require("./modules/form-validation");
var _globalPlayBtns = require("./modules/global-play-btns");
var _hidebox = require("./modules/hidebox");
var _anchors = require("./modules/anchors");
window.addEventListener('load', function () {
  var body = document.querySelector("body");
  var html = document.querySelector("html");
  // remove hidden classes =============================================
  var importantBlocks = document.querySelectorAll('.important__none');
  if (importantBlocks) {
    importantBlocks.forEach(function (block) {
      block.classList.remove('important__none');
    });
  }
  // remove hidden classes end =========================================

  // aos  ==============================================================
  setTimeout(function () {
    AOS.init({
      duration: 1000,
      once: true
    });
  }, 10);
  // aos end  ==========================================================

  // set vh height =====================================================
  var vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', "".concat(vh, "px"));
  // set vh height end =================================================

  // header ============================================================
  (0, _header.Header)(body, html);
  // header end ========================================================

  // validation ========================================================
  (0, _formValidation.formValidation)();
  // validation end ====================================================

  // global play btn ===================================================
  (0, _globalPlayBtns.globalPlayBtns)();
  // global play btn end ===============================================

  // hidebox ===========================================================
  (0, _hidebox.hideBoxFunction)();
  // hidebox end =======================================================

  // anchors ===========================================================
  (0, _anchors.anchorsFunction)(body, html);
  // anchors end =======================================================
});

// Resize
window.addEventListener('resize', function () {
  var vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', "".concat(vh, "px"));
});

},{"./modules/anchors":2,"./modules/form-validation":3,"./modules/global-play-btns":4,"./modules/header":5,"./modules/hidebox":6}],2:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.anchorsFunction = void 0;
var anchorsFunction = function anchorsFunction(body, html) {
  // anchor
  var anchorTrigger = document.querySelectorAll(".anchor-trigger");
  var anchorBlock = document.querySelectorAll(".anchor-block");
  var header = document.querySelector("header");
  if (anchorTrigger) {
    anchorTrigger.forEach(function (trigger) {
      trigger.addEventListener("click", function (e) {
        if (anchorBlock) {
          anchorBlock.forEach(function (b) {
            if (trigger.hasAttribute("data-anchor") && b.hasAttribute("data-block")) {
              if (trigger.getAttribute("data-anchor").toLowerCase() == b.getAttribute("data-block").toLowerCase()) {
                e.preventDefault();
                var boundingBlock = b.getBoundingClientRect().top;
                var boundingCount = window.scrollY + boundingBlock;
                window.scrollTo({
                  top: boundingCount - header.offsetHeight,
                  behavior: "smooth"
                });
                var headerMenu = header.querySelector(".header-mobile__menu");
                var overlay = document.querySelector(".overlay");
                var burger = document.querySelector(".header__burger");
                if (headerMenu && headerMenu.classList.contains("active")) {
                  headerMenu.classList.remove("active");
                  if (overlay) overlay.classList.remove("active");
                  if (burger) {
                    burger.classList.remove("active");
                  }
                  body.classList.remove("lock");
                  html.classList.remove("lock");
                }
              }
            }
          });
        }
      });
    });
  }
  // anchor end

  // online check block
  var onlineCheck = function onlineCheck() {
    if (!anchorBlock) return;
    anchorBlock.forEach(function (scrollBlock) {
      var topOffset = scrollBlock.offsetTop - (header.offsetHeight + 20);
      var allHeight = scrollBlock.offsetTop + scrollBlock.offsetHeight - (header.offsetHeight + 20);
      var stepId = scrollBlock.getAttribute("data-block");
      var activeNav = document.querySelector(".anchor-trigger[data-anchor=".concat(stepId, "]"));
      if (window.scrollY > topOffset && window.scrollY <= allHeight) {
        if (activeNav) {
          activeNav.classList.add('active');
        }
      } else {
        if (activeNav) {
          activeNav.classList.remove('active');
        }
      }
    });
  };
  window.addEventListener("scroll", onlineCheck);
  onlineCheck();
  // online check block end
};
exports.anchorsFunction = anchorsFunction;

},{}],3:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.formValidation = void 0;
var formValidation = function formValidation() {
  // itnl   ==========================================================
  var inputCountry = document.querySelectorAll(".country-number-field");
  if (inputCountry) {
    inputCountry.forEach(function (inputState) {
      if (inputState.classList.contains("eng-country")) {
        var iti = intlTelInput(inputState, {
          onlyCountries: ["us", "gb", "ua", "at", "bg", "cz", "dk", "ee", "fi", "fr", "de", "is", "ie", "it", "lv", "nl", "no", "pl", "sk", "si", "es", "se", "ch"],
          initialCountry: 'US',
          autoHideDialCode: false,
          preferredCountries: ["US", "GB", "UA"],
          // separateDialCode: true,
          nationalMode: false,
          utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/16.0.0/js/utils.js"
        });
      } else {
        var _iti = intlTelInput(inputState, {
          onlyCountries: ["us", "gb", "ua", "at", "bg", "cz", "dk", "ee", "fi", "fr", "de", "is", "ie", "it", "lv", "nl", "no", "pl", "sk", "si", "es", "se", "ch"],
          initialCountry: 'UA',
          autoHideDialCode: false,
          preferredCountries: ["UA", "GB", "US"],
          // separateDialCode: true,
          nationalMode: false,
          utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/16.0.0/js/utils.js"
        });
      }
    });
  }
  // itnl end   ========================================================

  //validate form ======================================================
  var forms = document.querySelectorAll('.form-container');
  forms.length && forms.forEach(function (item) {
    // email patern
    var email = item.querySelector('.mail');
    if (email) {
      var value = email.value;
      var parent = email.closest('.form-item');
      email.addEventListener('keyup', function (ev) {
        ev.stopPropagation();
        if (value.length < 1) {
          parent.classList.remove('error__email');
        }
      });
    }
    // email patern end

    item.addEventListener('submit', function (e) {
      e.preventDefault();
      var valide = true;
      var inputs = item.querySelectorAll('.form-required');

      // empty fields valid
      inputs.forEach(function (element) {
        var parent = element.closest('.form-item');
        if (element.value.trim() === '') {
          parent.classList.add('error');
          valide = false;
        } else {
          parent.classList.remove('error');
        }
      });
      // empty fields valid end

      // email validate
      if (email) {
        var pattern = /^[^ ]+@[^ ]+\.[a-z]{2,3}$/;
        var emailParent = email.closest('.form-item');
        var _value = email.value;
        if (_value.match(pattern)) {
          emailParent.classList.remove('error__email');
        } else {
          emailParent.classList.add('error__email');
        }
        if (emailParent && emailParent.classList.contains('error__email')) {
          valide = false;
        }
      }
      // email validate end

      if (valide) {
        fetch('/mail.php', {
          method: 'POST',
          body: new FormData(item)
        }).then(function (response) {
          if (response.ok) {
            return response.json();
          }
          return Promise.reject(response);
        }).then(function (data) {})['catch'](function (error) {
          console.warn(error);
        });

        // show thanks section
        var thanksSection = item.querySelector(".global-form-thanks");
        if (thanksSection) {
          thanksSection.classList.add("active");
          setTimeout(function () {
            thanksSection.classList.remove("active");
          }, 3000);
        }
        // show thanks section end

        // clear on sent
        var allInputs = item.querySelectorAll('.form__input');
        if (allInputs) {
          allInputs.forEach(function (el) {
            el.value = '';
          });
        }
        // clear on sent end

        // add country number
        var currentCountryFields = item.querySelectorAll(".country-number-field");
        if (currentCountryFields) {
          currentCountryFields.forEach(function (inputStateField) {
            var parentCountryField = inputStateField.closest(".form-item");
            if (parentCountryField) {
              var activeCountryField = parentCountryField.querySelector(".iti__country.iti__active");
              if (activeCountryField) {
                inputStateField.value = "+" + activeCountryField.getAttribute("data-dial-code");
              }
            }
          });
        }
        // add country number end
      }
    });
  });
  // clear on input
  var inputsField = document.querySelectorAll('.form-container .form-item');
  inputsField.forEach(function (field) {
    var inputField = field.querySelector('input');
    if (inputField) {
      inputField.addEventListener('input', function () {
        field.classList.remove('error');
      });
    }
  });
  // clear on input end
  // validate form end  ======================================================
};
exports.formValidation = formValidation;

},{}],4:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.globalPlayBtns = void 0;
var globalPlayBtns = function globalPlayBtns() {
  var globalPlays = document.querySelectorAll(".global-play-button");
  if (globalPlays) {
    globalPlays.forEach(function (playBtn) {
      var parentBox = playBtn.closest(".global-play-videobox");
      if (parentBox) {
        var video = parentBox.querySelector("video");
        if (video) {
          var timeReady;
          playBtn.addEventListener("click", function () {
            video.play();
          });
          video.addEventListener("play", function () {
            clearTimeout(timeReady);
            playBtn.classList.add("hide");
            video.setAttribute("controls", "true");
          });
          video.addEventListener("pause", function () {
            timeReady = setTimeout(function () {
              playBtn.classList.remove("hide");
            }, 200);
          });
          video.addEventListener("ended", function () {
            video.removeAttribute("controls");
          });
        }
      }
    });
  }
};
exports.globalPlayBtns = globalPlayBtns;

},{}],5:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Header = void 0;
var Header = function Header(body, html) {
  var header = document.querySelector(".header");
  var overlay = document.querySelector(".overlay");
  if (header) {
    // header menu
    var headerMenu = document.querySelector('.header-mobile__menu');
    var headerBurger = document.querySelector('.header__burger');
    if (headerMenu && headerBurger) {
      var closeMenu = function closeMenu() {
        headerMenu.classList.remove("active");
        headerBurger.classList.remove("active");
        body.classList.remove("lock");
        html.classList.remove("lock");
        if (overlay) {
          overlay.classList.remove("active");
        }
      };
      headerBurger.addEventListener('click', function () {
        headerMenu.classList.toggle('active');
        headerBurger.classList.toggle('active');
        body.classList.toggle('lock');
        html.classList.toggle('lock');
        if (overlay) {
          overlay.classList.toggle("active");
        }
      });
      window.addEventListener("resize", function () {
        if (window.innerWidth > 1024 && headerMenu.classList.contains("active")) {
          closeMenu();
        }
      });
    }
    // header menu end
  }
};
exports.Header = Header;

},{}],6:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.hideBoxFunction = void 0;
var hideBoxFunction = function hideBoxFunction() {
  var hideBoxContainers = document.querySelectorAll(".global-hidebox-content");
  if (hideBoxContainers) {
    hideBoxContainers.forEach(function (hideBox) {
      var hideBoxTrigger = hideBox.querySelector(".global-hidebox-btn");
      if (hideBoxTrigger) {
        hideBoxTrigger.onclick = function () {
          hideBoxTrigger.classList.add("active");
          hideBox.classList.add("active");
          AOS.refresh();
        };
      }
    });
  }
};
exports.hideBoxFunction = hideBoxFunction;

},{}]},{},[1]);
